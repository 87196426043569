import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth, db } from '../firebase'; // Import Firebase and Firestore instances
import { onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import Container from '../components/Containers/Container';
import LoadingSpinner from '../services/LoadingSpinner';
import Toast from '../services/Toast';

const API_URL = 'https://kfupm-courses-api.fly.dev'; // Update this URL

const GPACalculator = () => {
    const [file, setFile] = useState(null);
    const [courses, setCourses] = useState([{ code: '', grade: '', credits: '' }]);
    const [gpa, setGpa] = useState(null);
    const [user, setUser] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isLoadingCourses, setIsLoadingCourses] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                fetchUserData(currentUser.uid);
            } else {
                setUser(null);
                setCourses([{ code: '', grade: '', credits: '' }]);
                setGpa(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchUserData = async (userId) => {
        setIsLoadingCourses(true);
        try {
            const userDocRef = doc(db, 'Courses', userId);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                setCourses(userData.courses || [{ code: '', grade: '', credits: '' }]);
                calculateGPA(userData.courses || []);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setIsLoadingCourses(false);
        }
    };

    const saveUserData = async (userId, courses) => {
        if (!userId) {
            console.error('No user ID available');
            return;
        }
    
        try {
            const userDocRef = doc(db, 'Courses', userId);
            await setDoc(userDocRef, {
                courses: courses.map(course => ({
                    code: course.code || '',
                    credits: course.credits.toString(), // Convert to string to match rules
                    grade: course.grade || ''
                })).filter(course => course.code && course.credits && course.grade), // Remove empty entries
                updatedAt: serverTimestamp()
            }, { merge: true });
            
            console.log('Data saved successfully');
        } catch (error) {
            console.error('Error saving data:', error);
            throw error; // Re-throw to handle in the component
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) return;
        setIsUploading(true);
        const formData = new FormData();
        formData.append('transcript', file);
    
        try {
            const response = await axios.post(`${API_URL}/upload`, formData);
            const newCourses = [...response.data.courses, { code: '', grade: '', credits: '' }];
            setCourses(newCourses);
            calculateGPA(newCourses);
            if (user) {
                await saveUserData(user.uid, newCourses);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleManualCourseChange = (e, index) => {
        const { name, value } = e.target;
        const updatedCourses = [...courses];
        updatedCourses[index] = { ...updatedCourses[index], [name]: value };
        setCourses(updatedCourses);
        calculateGPA(updatedCourses);
        if (user) {
            saveUserData(user.uid, updatedCourses);
        }
    };

    const handleManualCourseAdd = () => {
        const newCourse = { code: '', grade: '', credits: '' };
        const updatedCourses = [...courses, newCourse];
        setCourses(updatedCourses);
        calculateGPA(updatedCourses);
        if (user) {
            saveUserData(user.uid, updatedCourses);
        }
    };

    const handleRemoveCourse = (index) => {
        const updatedCourses = [...courses];
        updatedCourses.splice(index, 1);
        setCourses(updatedCourses);
        calculateGPA(updatedCourses);
        if (user) {
            saveUserData(user.uid, updatedCourses);
        }
    };

    const calculateGPA = (courses) => {
        const gradePoints = {
            'A+': 4.00, 'A': 3.75, 'B+': 3.50, 'B': 3.00,
            'C+': 2.50, 'C': 2.00, 'D+': 1.50, 'D': 1.00, 'F': 0.00, 'NP': 0.00
        };

        let totalCredits = 0;
        let totalGradePoints = 0;

        courses.forEach(course => {
            const credits = parseFloat(course.credits);
            const grade = course.grade;
            if (grade !== 'NP' && grade !== '' && !isNaN(credits)) {
                totalCredits += credits;
                totalGradePoints += credits * gradePoints[grade];
            }
        });

        const gpa = totalCredits === 0 ? 0 : totalGradePoints / totalCredits;
        setGpa(gpa.toFixed(3));
    };

    const handleSave = async () => {
        if (!user) return;
        setIsSaving(true);
        
        try {
            const userDocRef = doc(db, 'Courses', user.uid);
            await setDoc(userDocRef, {
                courses: courses,
                gpa: gpa
            }, { merge: true });
            setShowToast(true); // Show success toast
        } catch (error) {
            console.error('Error saving data:', error);
            // You can also show an error toast here
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Container extraClasses='mx-12'>
            <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
                GPA Calculator
            </h1>

            {isLoadingCourses ? (
                <div className="flex justify-center items-center my-4">
                    <LoadingSpinner size="lg" />
                </div>
            ) : (
                <>
                    <div className="mb-8 w-full">
                        <label htmlFor="transcript" className="block text-sm font-medium text-gray-700 mb-2 ml-1">
                            Upload Transcript
                        </label>
                        <div className="flex flex-col justify-start md:flex-row md:justify-center md:items-center items-start space-y-2 md:space-x-4 ">
                            <input
                                id="transcript"
                                type="file"
                                onChange={handleFileChange}
                                className="block w-full text-xs sm:text-sm text-gray-500 file:mr-4 file:py-2 file:px-2 sm:file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-pink-50 file:text-pink-700 hover:file:bg-pink-100"
                            />
                            <button
                                onClick={handleUpload}
                                disabled={isUploading}
                                className="bg-pink-500 hover:bg-pink-600 text-white text-sm font-bold py-2 px-4 xs:px-6 sm:px-8 rounded-full transition duration-300 disabled:bg-pink-300 flex items-center space-x-2"
                            >
                                {isUploading ? (
                                    <>
                                        <LoadingSpinner size="sm" />
                                        <span>Uploading...</span>
                                    </>
                                ) : (
                                    'Upload'
                                )}
                            </button>
                        </div>
                    </div>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Courses</h2>
                    <div className="max-h-64 overflow-y-auto space-y-4 w-full">
                        {courses.map((course, index) => (
                            <div key={index} className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2 w-full ">
                                <input
                                    type="text"
                                    name="code"
                                    value={course.code}
                                    onChange={(e) => handleManualCourseChange(e, index)}
                                    placeholder="Course Code"
                                    className="flex-1 min-w-0 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                                />
                                <select
                                    name="grade"
                                    value={course.grade}
                                    onChange={(e) => handleManualCourseChange(e, index)}
                                    className="flex-1 min-w-0 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                                >
                                    <option value="">Select Grade</option>
                                    {['A+', 'A', 'B+', 'B', 'C+', 'C', 'D+', 'D', 'F', 'NP'].map(grade => (
                                        <option key={grade} value={grade}>{grade}</option>
                                    ))}
                                </select>
                                <input
                                    type="number"
                                    name="credits"
                                    value={course.credits}
                                    onChange={(e) => handleManualCourseChange(e, index)}
                                    placeholder="Credits"
                                    className="flex-1 min-w-0 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent "
                                />
                                {index < courses.length - 1 && (
                                    <button
                                        onClick={() => handleRemoveCourse(index)}
                                        className="text-pink-700 hover:text-pink-800 transition duration-300 rounded-full text-xl px-2 py-1"
                                    >
                                        ✖
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                    
                    <button
                        onClick={handleManualCourseAdd}
                        className="mt-6 bg-pink-100 hover:bg-pink-200 text-pink-500 font-bold py-2 px-4 rounded-full transition duration-300 mx-auto block"
                    >
                        + Add Course
                    </button>

                    <div className="mt-8 text-center">
                        <h2 className="text-3xl font-bold text-gray-800">
                            GPA: <span className="text-pink-600">{gpa || '0.000'}</span>
                        </h2>
                    </div>

                    <button
                        onClick={handleSave}
                        disabled={isSaving}
                        className="mt-6 bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 mx-auto block disabled:bg-pink-300 flex items-center space-x-2"
                    >
                        {isSaving ? (
                            <>
                                <LoadingSpinner size="sm" />
                                <span>Saving...</span>
                            </>
                        ) : (
                            'Save'
                        )}
                    </button>
                    {showToast && (
                        <Toast 
                            message="Changes saved successfully!" 
                            type="success"
                            onClose={() => setShowToast(false)}
                        />
                    )}
                </>
            )}
        </Container>
    );
};

export default GPACalculator;