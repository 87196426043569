import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import ToggleSwitch from "../Util/ToggleSwitch.jsx";
import { PiSailboatLight } from "react-icons/pi";
import { BsBookmark } from "react-icons/bs";
import { LuPen, LuRuler } from "react-icons/lu";
import Logout from "../Buttons/LogoutButton.jsx";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/userSlice.js";
import Logo from "../../assets/Logo.png";
import { Menu, X, Bookmark, PenLine, Calculator, Sailboat, LogOut } from "lucide-react";

const Sidebar = ({ enjoyTheView, onEnjoyTheView }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);

  const routes = [
    { str: "Book a Room", icon: <Bookmark />, path: "/booking/book-room" },
    { str: "Manage Bookings", icon: <PenLine />, path: "/booking/manage-booking" },
    "hr",
    { str: "GPA Calculator", icon: <Calculator />, path: "/gpa" },
  ];

  // Determine the default active index based on the URL path
  const getDefaultActiveIndex = () => {
    const currentPath = location.pathname;
    return routes.findIndex(route => route.path === currentPath);
  };

  const [activeIndex, setActiveIndex] = useState(getDefaultActiveIndex());
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setActiveIndex(getDefaultActiveIndex());
  }, [location.pathname]);

  function handleClick(index) {
    setIsOpen(false);
    setActiveIndex(index);
    const path = routes[index]["path"];
    navigate(path);
  }

  function toggleSidebar() {
    setIsOpen(!isOpen);
  }

  function handleEnjoyTheView() {
    onEnjoyTheView((prevState) => !prevState);
    if (enjoyTheView && isOpen) {
      return;
    }
    setIsOpen(false);
  }

  return (
    <>
      {/* Sidebar toggle button */}
      <div
        onClick={toggleSidebar}
        className={`text-2xl text-gray-700 flex ${
          isOpen
            ? "justify-start px-3 min-w-[250px]"
            : "justify-center max-w-[62.5px] w-[62.5px]"
        } py-4  z-50 fixed left-0 top-0 cursor-pointer rounded-br-xl`}
      >
        {isOpen ? <FaTimes className="" /> : <FaBars className="" />}
      </div>

      <div
        className={`text-2xl text-gray-700 flex ${
          isOpen
            ? "justify-center px-3 min-w-[250px] w-full sm:w-[250px]"
            : "justify-end max-w-[62.5px] w-[62.5px]"
        } py-3 z-40 fixed left-0 top-0 cursor-pointer rounded-br-xl`}
      >
        <h1
          className={`${
            isOpen ? " scale-100 duration-300" : "scale-0 absolute duration-0 "
          } transition-all  origin-left ease-in flex justify-center items-center relative`}
        >
          <img className="w-9 h-auto object-cover mr-1" src={Logo} alt="" />
          <span className="relative">
            Petronom
            <span className="absolute -right-4 top-[80%] text-[0.65rem] bg-orange-100 text-orange-600 px-1 py-0.5 rounded-md font-medium tracking-wide whitespace-nowrap">
              beta
            </span>
          </span>
        </h1>
      </div>

      <div
        className={`h-screen top-0 left-0 right-0 sm:bottom-0 sm:left-0 sm:top-0 sm:w-[250px] fixed z-30 bg-white shadow-lg pt-18 transition-transform duration-300 ease-in-out ${
          isOpen
            ? "sm:translate-x-0 items-start scale-100 origin-top-left"
            : "sm:-translate-x-3/4 items-end scale-0 sm:scale-100 origin-top-left"
        } flex flex-col justify-between`}
      >
        {/* Navigation Buttons section */}
        <div className={` ${isOpen ? "w-full px-2" : "w-1/4 px-1"} `}>
          {routes.map((route, index) => {
            if (route === "hr") {
              return <hr key={`hr-${index}`} className="my-2 border-gray-100" />;
            }

            return (
              <button
                key={index}
                onClick={() => handleClick(index)}
                className={`w-full rounded-xl mb-1 transition-all duration-200 ${
                  isOpen ? "px-3 py-2" : "p-2"
                } ${
                  activeIndex === index
                    ? "bg-orange-50 text-orange-600"
                    : "text-gray-600 hover:bg-gray-50"
                } flex items-center`}
              >
                <span className={`${isOpen ? "mr-3" : "mx-auto"}`}>{route.icon}</span>
                {isOpen && <span className="font-medium">{route.str}</span>}
              </button>
            );
          })}
        </div>

        {/* Enjoy the view button and logout button */}
        <div className={`z-50 ${isOpen ? "w-full" : "w-1/4"}`}>
          <div
            className={`w-full flex items-center ${
              isOpen ? "p-3 mb-2" : "justify-center"
            }`}
          >
            {isOpen && (
              <>
                <PiSailboatLight className="text-gray-500 text-2xl ml-2" />
                <span className="text-gray-500 ml-3 grow text-left">
                  Enjoy the View
                </span>
              </>
            )}
            <ToggleSwitch enabled={enjoyTheView} onToggle={handleEnjoyTheView} />
          </div>

          <div className="w-full px-2 py-3">
            {isOpen && (
              <div className=" w-full relative bg-Primary bg-opacity-65 rounded-xl p-3">
                <div
                  onClick={() => navigate(`/profile/${user.uid}`)}
                  className="absolute -translate-y-1/2 top-0 bg-white rounded-lg shadow-lg p-2 flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer"
                >
                  <img
                    src={`https://api.dicebear.com/9.x/pixel-art/svg?seed=${user.displayName}`}
                    className="w-8 h-8"
                  />
                </div>

                <div className="w-full flex justify-end items-center ">
                  <div className="bg-white rounded-lg shadow-sm p-1 flex justify-center items-center hover:scale-105 transition-all duration-300">
                    <Logout showText={false} />
                  </div>
                </div>

                <div className="flex flex-col">
                  <span className="text-gray-500 text-xs font-light">user</span>
                  <span className="text-sm font-bold text-gray-600">
                    {user.displayName}
                  </span>
                </div>
              </div>
            )}

            {!isOpen && (
              <div>
                <div
                  onClick={() => navigate(`/profile/${user.uid}`)}
                  className=" bg-white rounded-lg shadow-lg p-2 flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer"
                >
                  <img
                    src={`https://api.dicebear.com/9.x/pixel-art/svg?seed=${user.displayName}`}
                    className="w-8 h-8"
                  />
                </div>
                <div className="bg-red-600 bg-opacity-30 rounded-lg shadow-sm py-2.5 mt-2 flex justify-center items-center hover:scale-105 transition-all duration-300">
                  <Logout showText={false} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
