const SecondaryButton = ({ onClick, children }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onClick();
    };

    return (
        <button
            onClick={handleClick}
            className="bg-Secondary text-white py-2 px-4 rounded-full w-full hover:bg-opacity-80 active:scale-110 transition duration-200"
        >
            {children}
        </button>
    );
};

export default SecondaryButton;
