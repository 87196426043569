import React, { useState } from "react";
import moment from "moment";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import RadioButtons from "../Inputs/RadioButtons";
import NativeTimePicker from "../Inputs/NativeTimePicker";
import ListSelector from "../Inputs/ListSelector";
import SecondaryButton from "../Buttons/SecondaryButton";
import ValidationAlert from "../Alerts/ValidaitonAlert";

const SearchForm = ({ data, onSearch, selectedDay, setSelectedDay }) => {
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment().add(1, "hour"));
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  // Modify the errors state
const [errors, setErrors] = useState({
  day: false,
  time: false,
  duration: false,  // Add this new error type
  buildings: false
});
  

  // Convert HH:mm AM/PM format to minutes since midnight
  const convertTimeToMinutes = (momentTime) => {
    return momentTime.hours() * 60 + momentTime.minutes();
  };

  // Parse ISO string to minutes since midnight
  const parseISOToMinutes = (isoString) => {
    const time = moment(isoString);
    return time.hours() * 60 + time.minutes();
  };

  // Convert course time format (e.g., "0930-1045") to minutes
  const parseClassTime = (timeRange) => {
    if (!timeRange) return null;
    const [start, end] = timeRange.split("-");
    
    const startHour = parseInt(start.substring(0, 2));
    const startMinute = parseInt(start.substring(2));
    const startMinutes = startHour * 60 + startMinute;
    
    const endHour = parseInt(end.substring(0, 2));
    const endMinute = parseInt(end.substring(2));
    const endMinutes = endHour * 60 + endMinute;

    console.log(`[parseClassTime] ${start}-${end} -> `, {
      startMinutes,
      endMinutes,
      spansMidnight: startMinutes > endMinutes
    });
    
    return { start: startMinutes, end: endMinutes };
  };

  const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  };

  const hasTimeConflict = (searchStart, searchEnd, classStart, classEnd) => {
    console.log('\n[hasTimeConflict] Checking time overlap:');
    console.log(`Search period: ${formatMinutes(searchStart)} - ${formatMinutes(searchEnd)}`);
    console.log(`Class period: ${formatMinutes(classStart)} - ${formatMinutes(classEnd)}`);

    // Handle periods that span midnight
    const searchSpansMidnight = searchStart > searchEnd;
    const classSpansMidnight = classStart > classEnd;

    console.log(`Search spans midnight: ${searchSpansMidnight}`);
    console.log(`Class spans midnight: ${classSpansMidnight}`);

    let conflict = false;

    if (!searchSpansMidnight && !classSpansMidnight) {
      // Neither period spans midnight - simple overlap check
      conflict = searchStart < classEnd && classStart < searchEnd;
    } else if (searchSpansMidnight && classSpansMidnight) {
      // Both periods span midnight - they must overlap
      conflict = true;
    } else if (searchSpansMidnight) {
      // Search period spans midnight, class doesn't
      conflict = classStart <= searchEnd || classEnd >= searchStart;
    } else if (classSpansMidnight) {
      // Class spans midnight, search doesn't
      conflict = searchStart <= classEnd || searchEnd >= classStart;
    }

    console.log(`Conflict detected: ${conflict}`);
    return conflict;
  };

  const isRoomOccupied = (room, searchStartMinutes, searchEndMinutes, selectedDay) => {
    console.log(`\n[isRoomOccupied] Checking room ${room.building}-${room.room}`);
    console.log(`Search time: ${formatMinutes(searchStartMinutes)} - ${formatMinutes(searchEndMinutes)}`);
    console.log(`Selected day: ${selectedDay}`);

    const occupiedClasses = data.filter(course => {
      if (!course.Location || !course.Time || !course.Day) return false;

      const [building, roomNum] = course.Location.split("-");
      if (building !== room.building || roomNum !== room.room) return false;
      if (!course.Day.includes(selectedDay)) return false;

      const classTime = parseClassTime(course.Time);
      if (!classTime) return false;

      console.log(`\nEvaluating course: ${course["Course-Sec"]}`);
      console.log(`Day: ${course.Day}, Time: ${course.Time}`);

      const conflict = hasTimeConflict(
        searchStartMinutes,
        searchEndMinutes,
        classTime.start,
        classTime.end
      );

      if (conflict) {
        console.log(`❌ Conflict with ${course["Course-Sec"]} in ${room.building}-${room.room}`);
      }

      return conflict;
    });

    return occupiedClasses.length > 0;
  };

  const checkReservationConflict = (reservation, searchStart, searchEnd, selectedDay) => {
    console.log('\n[checkReservationConflict] Analyzing reservation:');
    console.log('Reservation:', reservation);
    
    const reservationStart = moment(reservation.startTime);
    const reservationEnd = moment(reservation.endTime);
    
    console.log(`Reservation period: ${reservationStart.format('YYYY-MM-DD HH:mm')} - ${reservationEnd.format('YYYY-MM-DD HH:mm')}`);
    console.log(`Search period: ${searchStart.format('YYYY-MM-DD HH:mm')} - ${searchEnd.format('YYYY-MM-DD HH:mm')}`);

    // Check if reservation is on the same day or spans to next day
    const reservationDay = reservationStart.format('YYYY-MM-DD');
    const searchDay = searchStart.format('YYYY-MM-DD');
    
    console.log(`Reservation day: ${reservationDay}`);
    console.log(`Search day: ${searchDay}`);

    // Convert times to minutes for comparison
    const resStartMinutes = parseISOToMinutes(reservation.startTime);
    const resEndMinutes = parseISOToMinutes(reservation.endTime);
    const searchStartMinutes = convertTimeToMinutes(searchStart);
    const searchEndMinutes = convertTimeToMinutes(searchEnd);

    const conflict = hasTimeConflict(
      searchStartMinutes,
      searchEndMinutes,
      resStartMinutes,
      resEndMinutes
    );

    console.log(`Conflict detected: ${conflict}`);
    return conflict;
  };

  const getNextDate = (day) => {
    const daysOfWeek = ["U", "M", "T", "W", "R", "F", "S"];
    const today = moment();
    const targetDay = daysOfWeek.indexOf(day);
    
    // If it's the same day, we need to check the time
    if (targetDay === today.day()) {
      const currentTime = moment();
      const selectedTime = moment(startTime);
      
      // If the selected time hasn't passed yet today, use today's date
      if (selectedTime.isAfter(currentTime)) {
        return today;
      }
      // If the time has passed, get next week's date
      return today.add(7, "days");
    }
    
    // For different days, calculate the next occurrence
    let nextDate = moment().day(targetDay);
    if (targetDay <= today.day()) {
      nextDate = nextDate.add(7, "days");
    }
    
    return nextDate;
  };

  const handleSearch = async () => {

    
    if (!moment.isMoment(startTime) || !moment.isMoment(endTime)) {
      console.error("Invalid startTime or endTime");
      return;
    }
  
    // Calculate the duration in minutes
    const durationInMinutes = moment.duration(endTime.diff(startTime)).asMinutes();
  
    // Debugging logs
    console.log("startTime:", startTime.format("YYYY-MM-DD HH:mm"));
    console.log("endTime:", endTime.format("YYYY-MM-DD HH:mm"));
    console.log("durationInMinutes:", durationInMinutes);
  
    // Set errors based on validation checks
    setErrors({
      day: !selectedDay,
      time: startTime >= endTime,
      duration: durationInMinutes < 60,  // Check if duration is less than 1 hour
      buildings: selectedBuildings.length === 0
    });
  


    if (!data) {
      console.error("Data is undefined");
      return;
    }
  
    console.log(`\n=== Starting Room Search ===`);
    console.log(`Day: ${selectedDay}`);
    console.log(`Selected Buildings:`, selectedBuildings.map(b => b.value).join(", "));
  
    const nextDate = getNextDate(selectedDay);
    const selectedStartTimeFormatted = moment(nextDate).set({
      hour: startTime.hour(),
      minute: startTime.minute(),
      second: 0,
      millisecond: 0,
    });
    const selectedEndTimeFormatted = moment(nextDate).set({
      hour: endTime.hour(),
      minute: endTime.minute(),
      second: 0,
      millisecond: 0,
    });

    // Adjust end time if it's before start time (crosses midnight)
    if (selectedEndTimeFormatted.isBefore(selectedStartTimeFormatted)) {
      selectedEndTimeFormatted.add(1, 'day');
    }
  
    console.log(`Search period: ${selectedStartTimeFormatted.format()} - ${selectedEndTimeFormatted.format()}`);
  
    // Fetch all reservations for the day
    const db = getFirestore();
    const reservationsRef = collection(db, "Reservations");
    
    let reservations = [];
    try {
      const reservationsSnapshot = await getDocs(reservationsRef);
      reservations = reservationsSnapshot.docs.map(doc => doc.data());
      console.log(`Found ${reservations.length} total reservations`);
    } catch (error) {
      console.error("Error fetching reservations:", error);
      return;
    }
  
    const occupiedRooms = new Set();
    
    // Check each reservation for conflicts
    reservations.forEach(reservation => {
      const conflict = checkReservationConflict(
        reservation,
        selectedStartTimeFormatted,
        selectedEndTimeFormatted,
        selectedDay
      );
      
      if (conflict) {
        console.log(`Room ${reservation.building}-${reservation.room} is reserved`);
        occupiedRooms.add(`${reservation.building}-${reservation.room}`);
      }
    });
  
    const availableRooms = new Map();
    const processedRooms = new Set();
  
    // Process each unique room from course data
    data.forEach((course) => {
      if (!course.Location) return;
  
      const [building, roomNum] = course.Location.split("-");
      if (!building || !roomNum) return;
  
      const room = { building, room: roomNum };
      const key = `${room.building}-${room.room}`;
  
      if (processedRooms.has(key)) return;
      processedRooms.add(key);
  
      const isBuildingSelected = selectedBuildings.some(
        (building) => building.value === room.building
      );
  
      if (!isBuildingSelected) return;
  
      if (occupiedRooms.has(key)) {
        console.log(`Room ${key} is reserved`);
        return;
      }
  
      const searchStartMinutes = convertTimeToMinutes(startTime);
      const searchEndMinutes = convertTimeToMinutes(endTime);
  
      if (isRoomOccupied(room, searchStartMinutes, searchEndMinutes, selectedDay)) {
        console.log(`Room ${key} has scheduled classes`);
        return;
      }
  
      console.log(`✅ Room ${key} is available`);
      availableRooms.set(key, room);
    });
  
    const sortedRooms = Array.from(availableRooms.values()).sort(
      (a, b) => a.building.localeCompare(b.building) || a.room.localeCompare(b.room)
    );

    if (!selectedDay || startTime >= endTime || selectedBuildings.length === 0) {
      return;
    }
  
    console.log(`\n=== Search Results ===`);
    console.log(`Found ${sortedRooms.length} available rooms`);
    console.log("Available rooms:", sortedRooms.map(room => `${room.building}-${room.room}`).join(", "));
  
    onSearch(
      sortedRooms,
      selectedDay,
      selectedStartTimeFormatted,
      selectedEndTimeFormatted
    );
  };

  // Get building options from JSON data
  const buildingOptions = Array.from(
    new Set(
      data.map((course) =>
        course.Location ? course.Location.split("-")[0] : ""
      )
    )
  )
    .filter((building) => building)
    .sort()
    .map((building) => ({ value: building, label: `Building ${building}` }));

  const dayMap = ["U", "M", "T", "W", "R", "F", "S"];

  return (
    <form className="">
      {/* Day selection validation */}
      {errors.day && <ValidationAlert message="Please select a day" />}
      <div className="flex justify-start items-center mt-3 w-full">
        <RadioButtons
          choices={dayMap}
          onSelectChoice={(choice) => setSelectedDay(choice)}
          selectedChoice={selectedDay}
          isDaysOfWeek={true}
        />
      </div>
  
      {/* Time selection validation */}
      {errors.time && <ValidationAlert message="End time must be after start time" />}
      {errors.duration && <ValidationAlert message="The duration must be at least 1 hour" />}  {/* New validation alert */}
      <div className="flex items-center mt-3">
  <div className="mr-10 w-1/2">
    <NativeTimePicker
      defaultDate={startTime.toDate()} // Convert moment to Date object
      label={"Start Time"}
      onSetTime={(date) => {
        const newTime = moment(date);
        setStartTime(newTime);
        
        // Ensure end time is at least 1 hour after start time
        const duration = moment.duration(endTime.diff(newTime)).asMinutes();
        if (duration < 60) {
          setEndTime(moment(newTime).add(1, 'hour'));
        }
      }}
    />
  </div>
  <div className="w-1/2">
    <NativeTimePicker
      defaultDate={endTime.toDate()} // Convert moment to Date object
      label={"End Time"}
      onSetTime={(date) => {
        const newTime = moment(date);
        setEndTime(newTime);
        
        // Ensure end time is at least 1 hour after start time
        const duration = moment.duration(newTime.diff(startTime)).asMinutes();
        if (duration < 60) {
          setStartTime(moment(newTime).subtract(1, 'hour'));
        }
      }}
    />
  </div>
</div>
  
      {/* Building selection validation */}
      {errors.buildings && <ValidationAlert message="Please select at least one building" />}
      <div className="mt-3 mb-3">
        <ListSelector
          items={buildingOptions}
          selectedItems={selectedBuildings}
          onSelectItem={setSelectedBuildings}
          label="Select Buildings"
        />
      </div>
  
      <SecondaryButton className="mb-2" onClick={handleSearch}>
        Search
      </SecondaryButton>
    </form>
  );
};

export default SearchForm;