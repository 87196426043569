import React, { useEffect, useState } from 'react';
import App from './App.jsx';
import { storage } from './firebase.js'; // Ensure this path is correct
import { ref, getDownloadURL } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { selectRootData, setRootData } from './store/rootDataSlice.js';
import LoadingSpinner from './LoadingSpinner.jsx';

const Root = () => {
  const [error, setError] = useState(null); // Add error state
  const rootData = useSelector(selectRootData)
  const dispatch = useDispatch()

  useEffect(() => {
    // console.log('root data:', rootData)
  }, [rootData])

  const fetchData = async () => {
    try {
      // Create a reference to the file in Firebase Storage
      const storageRef = ref(storage, 'kfupm_course_data.json'); // Adjust the path as necessary

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      // Fetch the data from the download URL
      const response = await fetch(downloadURL); 

      // Check if the response is OK (status in the range 200-299)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Parse the response as JSON
      const data = await response.json();
      
      // Set the data in state
      dispatch(setRootData({rootData: data}))
    } catch (error) {
      // Handle errors
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchData(); // Call fetchData directly inside useEffect
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!rootData) {
    return <LoadingSpinner></LoadingSpinner>
  }

  return <App />;
};

export default Root;
