import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { Calendar, Clock, MapPin, AlertCircle } from "lucide-react";
import Container from "../components/Containers/Container";
import LoadingSpinner from "../components/Util/LoadingSpinner";

const ITEMS_PER_PAGE = 5;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex justify-center items-center space-x-2 mt-6">
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`
            w-10 h-10 rounded-full font-bold text-sm
            transition duration-300 hover:scale-105
            ${currentPage === number
              ? "bg-pink-500 text-white"
              : "bg-pink-100 text-pink-700 hover:bg-pink-200"
            }
          `}
        >
          {number}
        </button>
      ))}
    </div>
  );
};

const BookingHistory = () => {
  const [bookings, setBookings] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchBookings(currentUser.uid);
      } else {
        setUser(null);
        setBookings([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchBookings = async (userId) => {
    try {
      setLoading(true);
      const q = query(
        collection(db, "Reservations"),
        where("uid", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      const bookingsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBookings(bookingsData);
    } catch (err) {
      setError("Failed to fetch bookings");
      console.error("Error fetching bookings:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelBooking = async (bookingId) => {
    if (window.confirm("Are you sure you want to cancel this booking?")) {
      try {
        await deleteDoc(doc(db, "Reservations", bookingId));
        setBookings(bookings.filter((booking) => booking.id !== bookingId));
      } catch (err) {
        setError("Failed to cancel booking");
        console.error("Error canceling booking:", err);
      }
    }
  };

  if (loading) return <LoadingSpinner />;

  const now = new Date();
  const sortedBookings = bookings.sort((a, b) => {
    const startA = new Date(a.startTime);
    const startB = new Date(b.startTime);
    const endA = new Date(a.endTime);
    const endB = new Date(b.endTime);

    // Helper function to determine booking status
    const getStatus = (start, end) => {
      if (now < start) return 0; // Upcoming
      if (now >= start && now <= end) return 1; // In Progress
      return 2; // Completed
    };

    const statusA = getStatus(startA, endA);
    const statusB = getStatus(startB, endB);

    // First sort by status
    if (statusA !== statusB) {
      return statusA - statusB;
    }

    // Within the same status, sort by start time
    return startA - startB;
  });

  const totalPages = Math.ceil(sortedBookings.length / ITEMS_PER_PAGE);
  const currentBookings = sortedBookings.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <Container extraClasses="mx-12">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">Room Bookings</h1>
      </div>

      {error && (
        <div className="mb-8 p-4 bg-pink-50 border border-pink-200 text-pink-700 rounded-lg flex items-center space-x-2">
          <AlertCircle className="h-5 w-5" />
          <p className="font-medium">{error}</p>
        </div>
      )}

      <div className="space-y-4">
        {sortedBookings.length === 0 ? (
          <div className="text-center py-16 bg-pink-50 rounded-xl">
            <Calendar className="h-16 w-16 text-pink-300 mx-auto mb-4" />
            <h2 className="text-2xl font-semibold text-gray-800">
              No Room Bookings Found
            </h2>
            <p className="text-gray-600 mt-2">
              Your room reservations will appear here
            </p>
          </div>
        ) : (
          <>
            <div className="max-h-[calc(100vh-300px)] overflow-y-auto space-y-4 pr-2">
              {currentBookings.map((booking) => (
                <BookingCard
                  key={booking.id}
                  booking={booking}
                  onCancel={handleCancelBooking}
                />
              ))}
            </div>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </>
        )}
      </div>
    </Container>
  );
};

const BookingCard = ({ booking, onCancel }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const getBookingStatus = (startTime, endTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const end = new Date(endTime);

    if (now < start) return { text: "Upcoming", color: "bg-green-100 text-green-700" };
    if (now >= start && now <= end) return { text: "In Progress", color: "bg-pink-100 text-pink-700" };
    return { text: "Completed", color: "bg-gray-100 text-gray-700" };
  };

  const { building, room, startTime, endTime, id } = booking;
  const status = getBookingStatus(startTime, endTime);

  return (
    <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300">
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <div className="space-y-2">
            <div className="flex items-center text-gray-600">
              <MapPin className="h-4 w-4 mr-2" />
              <span className="text-sm font-medium">Location</span>
            </div>
            <p className="text-lg font-semibold text-pink-700">
              Building {building}, Room {room}
            </p>
          </div>

          <div className="md:col-span-2 space-y-2">
            <div className="flex items-center text-gray-600">
              <Clock className="h-4 w-4 mr-2" />
              <span className="text-sm font-medium">Duration</span>
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center gap-2">
              <span className="text-pink-700 font-medium">{formatDate(startTime)}</span>
              <span className="hidden sm:block text-gray-400">→</span>
              <span className="text-pink-700 font-medium">{formatDate(endTime)}</span>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row md:flex-col justify-between gap-4">
            <span className={`inline-flex items-center justify-center px-4 py-1.5 rounded-full text-sm font-medium ${status.color}`}>
              {status.text}
            </span>
            
            {status.text === "Upcoming" && (
              <button
                onClick={() => onCancel(id)}
                className="bg-pink-500 hover:scale-105 text-white font-bold py-2 px-4 rounded-full transition duration-300"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingHistory;