import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const BookingModal = ({ show, onHide, onConfirm }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to book this room?</p>
      </Modal.Body>
      <Modal.Footer>
        <div className='flex justify-end gap-2'>
        <SecondaryButton onClick={onHide}>
          No
        </SecondaryButton>
        <PrimaryButton onClick={onConfirm}>Yes</PrimaryButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BookingModal;
