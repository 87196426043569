// services/Auth/SigninService.js
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";

export const validateSignInInputs = (inputs) => {
    const errors = {};
    const { email, password } = inputs;

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
        errors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
        errors.email = "Email is not valid.";
    }

    // Validate password
    if (!password) {
        errors.password = "Password is required.";
    }

    return errors;
};

export const signInUser = async ({ email, password }) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Check if email is verified
        if (!user.emailVerified) {
            throw new Error("Please verify your email before signing in. Check your inbox for the verification link.");
        }

        return { 
            success: true,
            user,
            message: "Successfully signed in!"
        };
    } catch (error) {
        let errorMessage;
        switch (error.code) {
            case 'auth/user-not-found':
            case 'auth/wrong-password':
                errorMessage = "Invalid email or password.";
                break;
            case 'auth/user-disabled':
                errorMessage = "This account has been disabled.";
                break;
            case 'auth/too-many-requests':
                errorMessage = "Too many failed attempts. Please try again later.";
                break;
            default:
                errorMessage = error.message;
        }
        throw new Error(errorMessage);
    }
};