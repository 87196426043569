import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const AuthRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  return user.uid ? children : <Navigate to="/auth/sign-in" />;
};

export const GuestRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  return user.uid ? <Navigate to="/home" /> : children;
};

// Added ForgotPasswordRoute since it should only be accessible to non-authenticated users
export const ForgotPasswordRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  return user.uid ? <Navigate to="/home" /> : children;
};