import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSignUp: false
};

const styleSlice = createSlice({
  name: 'styleSlice',
  initialState,
  reducers: {
    setStyle: (state, action) => {
      console.log("Setting isSignUp", action.payload.isSignUp);
      return {
        ...state,
        isSignUp: action.payload.isSignUp,
      };
    },
    
  },
});

export const { setStyle } = styleSlice.actions;
export const selectIsSignUp = (state) => state.style.isSignUp
export default styleSlice.reducer;