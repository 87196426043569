import { RiLockPasswordLine } from "react-icons/ri";
import { forwardRef } from "react";

const PasswordInput = forwardRef(({ text = "Password" }, ref) => {
  return (
    <div className="flex items-center border border-gray-300 rounded-lg p-2 w-full my-1">
      <RiLockPasswordLine className="text-gray-400 mr-1 w-6 h-6" />
      <input
        ref={ref}
        type="password"
        placeholder={text}
        className="outline-none flex-grow text-gray-600 placeholder-gray-400 w-full"
      />
    </div>
  );
});

export default PasswordInput;
