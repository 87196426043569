// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";  // Firebase Storage
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";  // Firebase Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMWfXydbLhQUQuF_Nqtd832bTWlMgIVQA",
  authDomain: "kfupmclasses.firebaseapp.com",
  projectId: "kfupmclasses",
  storageBucket: "kfupmclasses.appspot.com",
  messagingSenderId: "57579786710",
  appId: "1:57579786710:web:94c91b92364080860b1477",
  measurementId: "G-EP4YFFDTKN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);  // Export storage instance
export const auth = getAuth(app);
export const db = getFirestore(app);  // Export Firestore instance
