const PrimaryButton = ({onClick, disabled = false, children}) => {

    const handleClick = (e) => {
        e.preventDefault()
        onClick()
    }
    return (
        <button 
        disabled={disabled}
            onClick={handleClick}
            className="bg-Primary text-white py-2 px-4 rounded-full w-full hover:bg-opacity-90 active:scale-110 transition duration-200 flex justify-center items-center gap-x-1 disabled:cursor-not-allowed disabled:bg-opacity-50"
        >
            {children}
        </button>
    )
}

export default PrimaryButton;