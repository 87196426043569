import React from 'react';
import { AlertCircle } from "lucide-react";

const ValidationAlert = ({ message }) => {
  return (
    <div className="flex items-center gap-2 p-2 mb-2 text-sm text-red-600 rounded-md bg-red-50 border border-red-200">
      <AlertCircle className="h-4 w-4" />
      <span>{message}</span>
    </div>
  );
};

export default ValidationAlert;