import React from 'react';
import { motion } from 'framer-motion';
import { Check, Clock, Building2, User } from 'lucide-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const BookingConfirmationModal = ({ isOpen, onClose, bookingDetails }) => {
  if (!isOpen || !bookingDetails) return null;

  const {
    building,
    room,
    startTime,
    endTime,
    displayName,
    email
  } = bookingDetails;
  const navigate = useNavigate();
  const formatTime = (time) => moment(time).format('h:mm A');
  const formatDate = (time) => moment(time).format('dddd, MMMM Do YYYY');

  const handleClose = () => {
    console.log('Hello World');
    
    onClose();
    navigate('/booking/manage-booking');
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/40"
        onClick={handleClose}
      />
      
      <motion.div
        initial={{ scale: 0.9, opacity: 0, y: 20 }}
        animate={{ scale: 1, opacity: 1, y: 0 }}
        exit={{ scale: 0.9, opacity: 0, y: 20 }}
        transition={{ type: "spring", duration: 0.5 }}
        className="relative bg-white rounded-xl shadow-lg w-full max-w-md mx-4 overflow-hidden"
      >
        {/* Success Banner */}
        <div className="bg-gradient-to-r from-orange-400 to-red-500 p-6 text-white text-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring" }}
            className="mb-4"
          >
            <div className="mx-auto w-16 h-16 bg-white rounded-full flex items-center justify-center">
              <Check className="w-8 h-8 text-green-500" />
            </div>
          </motion.div>
          <h2 className="text-2xl font-bold mb-2">Booking Confirmed!</h2>
          <p className="text-white/90">Your classroom has been successfully booked</p>
        </div>

        {/* Booking Details */}
        <div className="p-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="space-y-4"
          >
            {/* Location */}
            <div className="flex items-start space-x-3">
              <Building2 className="w-5 h-5 text-gray-500 mt-1" />
              <div>
                <p className="font-medium text-gray-900">Building {building}</p>
                <p className="text-gray-600">Room {room}</p>
              </div>
            </div>

            {/* Time */}
            <div className="flex items-start space-x-3">
              <Clock className="w-5 h-5 text-gray-500 mt-1" />
              <div>
                <p className="font-medium text-gray-900">{formatDate(startTime)}</p>
                <p className="text-gray-600">
                  {formatTime(startTime)} - {formatTime(endTime)}
                </p>
              </div>
            </div>

            {/* User */}
            <div className="flex items-start space-x-3">
              <User className="w-5 h-5 text-gray-500 mt-1" />
              <div>
                <p className="font-medium text-gray-900">{displayName}</p>
                <p className="text-gray-600">{email}</p>
              </div>
            </div>
          </motion.div>

          {/* Close Button */}
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            onClick={handleClose}
            className="mt-6 w-full bg-gradient-to-r from-orange-400 to-red-500 text-white py-3 px-4 rounded-lg font-medium hover:opacity-90 transition-opacity"
          >
            Close
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default BookingConfirmationModal;