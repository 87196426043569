import {
  getFirestore,
  collection,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import moment from "moment";
import { checkIfTimePassed, getNextDate, setStartEndBookingTimes } from "./DateService";

export const handleConfirmBooking = async (
  selectedRoom,
  selectedBuilding,
  selectedDay,
  startTime,
  endTime,
  user,
  setAvailableRooms
) => {
  console.log(
    "handleConfirmBooking():",
    "selectedRoom:",
    selectedRoom,
    ", selectedBuilding:",
    selectedBuilding,
    ", selectedDay:",
    selectedDay,
    ", startTime:",
    startTime,
    ", endTime:",
    endTime
  );
  if (!selectedRoom || !user) return;

  const db = getFirestore();

  // Current local time when the booking is made (for 'bookingDate')
  const bookingTime = moment(); // Get the current time in local time

  // Get the next occurrence of the selected day (e.g., next Monday if Monday is selected)
  const nextDate = getNextDate(selectedDay); // This is now a moment object

  // Check if the selected time for today has already passed
  const { isToday, currentTime, selectedStartTime } = checkIfTimePassed(
    nextDate,
    startTime
  );

  console.log("Selected Day:", selectedDay); // Debugging log
  console.log("Next Date:", nextDate.format()); // Debugging log
  console.log("Is Today:", isToday); // Debugging log
  console.log("Current Time:", currentTime.format()); // Debugging log
  console.log("Selected Start Time:", selectedStartTime.format()); // Debugging log

  if (isToday && currentTime.isAfter(selectedStartTime)) {
    // If the selected time for today has passed, move to the next occurrence of the selected day
    nextDate.add(7, "days");
    console.log("Adjusted Next Date:", nextDate.format()); // Debugging log
  }

  // Set the selected day with the chosen start time
  const { bookingStartTime, bookingEndTime } = setStartEndBookingTimes(
    nextDate,
    startTime,
    endTime
  );

  console.log("Booking Start Time:", bookingStartTime.format()); // Debugging log
  console.log("Booking End Time:", bookingEndTime.format()); // Debugging log

  try {
    // Save the reservation details to Firestore
    await addDoc(collection(db, "Reservations"), {
      building: selectedRoom.building,
      room: selectedRoom.room,
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      startTime: bookingStartTime.format(), // Start time on the correct future day
      endTime: bookingEndTime.format(), // End time on the correct future day
      bookingDate: bookingTime.format(), // Current booking date/time in local time
    });

    // Remove reserved room from availability
    const roomRef = doc(
      db,
      "Reservations",
      `${selectedRoom.building}-${selectedRoom.room}`
    );
    await deleteDoc(roomRef);

    // Update available rooms list
    setAvailableRooms((prevRooms) =>
      prevRooms.filter((room) => room !== selectedRoom)
    );

  } catch (error) {
    console.error("Error saving booking:", error);
  }
};
