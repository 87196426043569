import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase.js";
import EmailInput from "../Inputs/EmailInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import CreativityImage from "../../assets/orange-art.svg";
import { selectIsSignUp, setStyle } from "../../store/styleSlice";
import LoadingSpinner from "../Util/LoadingSpinner";
import Divider from "../Util/Divider";
import { Lock, ArrowLeft } from "lucide-react";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSignUp = useSelector(selectIsSignUp);
  
  const emailRef = useRef(null);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(setStyle({ isSignUp: false }));
  }, [dispatch]);

  const handleResetPassword = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    setError({});

    const email = emailRef.current.value;
    
    if (!email) {
      setError({ email: "Email is required" });
      setIsLoading(false);
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError({ email: "Please enter a valid email" });
      setIsLoading(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setTimeout(() => navigate("/auth/sign-in"), 3000);
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          setError({ email: "No account found with this email" });
          break;
        case 'auth/invalid-email':
          setError({ email: "Invalid email format" });
          break;
        case 'auth/too-many-requests':
          setError({ general: "Too many attempts. Please try again later" });
          break;
        default:
          setError({ general: "Failed to send reset email. Please try again." });
      }
    }
    
    setIsLoading(false);
  };

  return (
    <>
      <div className={`${isSignUp ? "scale-0" : "scale-100"} transition-all duration-300 w-full flex justify-center items-center h-48 max-h-48 sm:max-h-[300px] sm:h-1/3 md:w-1/2 md:h-full z-10`}>
        <img src={CreativityImage} className="w-auto h-full object-cover md:pr-5" alt="Creativity" />
      </div>
      <div className={`${isSignUp ? "scale-0 absolute" : "scale-100"} transition-all duration-300 z-50 w-full h-2/3 md:w-1/2 md:h-full md:ml-10 flex flex-col justify-center items-center`}>
        <div className="w-full max-w-md px-6 md:px-0">
          <button
            onClick={() => navigate("/auth/sign-in")}
            className="flex items-center text-gray-500 hover:text-blue-600 transition-colors mb-1 sm:mb-8 group mt-3 sm:mt-0"
          >
            <ArrowLeft className="w-4 h-4 mr-2 transition-transform group-hover:-translate-x-1" />
            Back to Sign In
          </button>

          <div className="mb-8">
            <h1 className="text-2xl font-bold mb-2">Reset Password</h1>
            <p className="text-gray-600">
              Enter your email address and we'll send you instructions to reset your password.
            </p>
          </div>

          {!success ? (
            <form onSubmit={handleResetPassword} className="space-y-6">
              <div className="space-y-1">
                <EmailInput ref={emailRef} />
                {error.email && (
                  <p className="text-red-500 text-sm">{error.email}</p>
                )}
                {error.general && (
                  <p className="text-red-500 text-sm">{error.general}</p>
                )}
              </div>

              <PrimaryButton
                onClick={handleResetPassword}
                disabled={isLoading}
                className="w-full h-11 relative"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <LoadingSpinner className="w-5 h-5" />
                    <span className="ml-2">Sending...</span>
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <Lock className="w-4 h-4 mr-2" />
                    Reset Password
                  </div>
                )}
              </PrimaryButton>
            </form>
          ) : (
            <div className="text-center bg-green-50 rounded-lg p-8 border border-green-100">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <div className="text-green-500 text-2xl">✓</div>
              </div>
              <h2 className="text-xl font-semibold mb-2">Check Your Email</h2>
              <p className="text-gray-600 mb-1">
                We've sent password reset instructions to your email.
              </p>
              <p className="text-gray-500 text-sm">
                Redirecting to sign in...
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;