const LOREM_IPSUM = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,"


const Container = ({minHeight='', height='', extraClasses='', children}) => {
    return <div className={`
    
    p-4 md:p-6 lg:p-8
    xs:max-w-xl lg:max-w-3xl xl:max-w-4xl
    bg-white shadow-lg rounded-xl
    ${minHeight} ${height} ${extraClasses}
    grid place-items-center
    relative
    `}>
        {children}
    </div>
}

export default Container;