import moment from "moment";

export const getNextDate = (day) => {
    const today = moment(); // Get current moment
    let nextDate = moment(); // Initialize with today

    // Map of days: Sunday (0) to Saturday (6)
    const dayMap = {
      U: 0, // Sunday
      M: 1, // Monday
      T: 2, // Tuesday
      W: 3, // Wednesday
      R: 4, // Thursday
      F: 5, // Friday
      S: 6, // Saturday
    };

    const targetDay = dayMap[day]; // Get the number for the selected day
    const currentDay = today.day(); // Get today's day number

    // Calculate the difference in days to the next occurrence of the selected day
    const daysToAdd = (targetDay - currentDay + 7) % 7;
    if (daysToAdd === 0) {
      nextDate = today.clone(); // If today is the target day, keep today's date
    } else {
      nextDate = today.clone().add(daysToAdd, "days"); // Otherwise, move to the next occurrence
    }

    return nextDate; // Return the next occurrence of the selected day
  };

// Check if the selected time for today has already passed
export const checkIfTimePassed = (nextDate, startTime) => {
  const isToday = nextDate.isSame(moment(), "day");
    const currentTime = moment();
    const selectedStartTime = moment(nextDate).set({
      hour: startTime.hour(),
      minute: startTime.minute(),
      second: 0,
      millisecond: 0,
    });

    return {
      isToday,
      currentTime,
      selectedStartTime
    }
}

export const setStartEndBookingTimes = (nextDate, startTime, endTime) => {
  // Set the selected day with the chosen start time
  const bookingStartTime = moment(nextDate).set({
    hour: startTime.hour(),
    minute: startTime.minute(),
    second: 0,
    millisecond: 0,
  });

  // Set the selected day with the chosen end time
  const bookingEndTime = moment(nextDate).set({
    hour: endTime.hour(),
    minute: endTime.minute(),
    second: 0,
    millisecond: 0,
  });

  return {
    bookingStartTime,
    bookingEndTime,
  }
}