import React, { useState, useEffect } from "react";

const NativeTimePicker = ({ label = "Select Time", onSetTime, defaultTime }) => {
  const [currentTime, setCurrentTime] = useState(defaultTime || "");

  useEffect(() => {
    if (!defaultTime) {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const initialTime = `${hours}:${minutes}`;
      setCurrentTime(initialTime);

      if (onSetTime) {
        onSetTime(initialTime);
      }
    } else if (onSetTime) {
      onSetTime(defaultTime);
    }
  }, [defaultTime]);

  const handleTimeChange = (e) => {
    const newTime = e.target.value;
    setCurrentTime(newTime);

    if (onSetTime) {
      function createDateFromTime(timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
      }

      const dateObj = createDateFromTime(newTime);
      onSetTime(dateObj);
    }
  };

  return (
    <div className="relative">
      <input
        type="time"
        id="time"
        name="time"
        value={currentTime}
        onChange={handleTimeChange}
        className="peer block w-full appearance-none border border-gray-300 rounded-md p-3 text-base text-gray-600 bg-white focus:outline-none focus:ring-2 focus:ring-Secondary focus:border-Secondary"
        required
      />
      <label
        htmlFor="time"
        className="absolute left-3 -top-2 bg-white px-1 text-sm text-gray-500 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-2 peer-focus:text-sm peer-focus:text-Secondary"
      >
        {label}
      </label>
    </div>
  );
};

export default NativeTimePicker;
