import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rootData: null
};

const rootDataSlice = createSlice({
  name: 'rootData',
  initialState,
  reducers: {
    setRootData: (state, action) => {
      // console.log("Setting rootData:", action.payload);
      return {
        ...state,
        rootData: action.payload.rootData,
      };
    },
    
  },
});

export const { setRootData } = rootDataSlice.actions;
export const selectRootData = (state) => state.rootData.rootData
export default rootDataSlice.reducer;