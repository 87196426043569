import { Outlet } from "react-router-dom";
import Waves from "../StylingComponents/Waves/Waves";
import MainBackground from "../StylingComponents/MainBackground/MainBackground";
import Sidebar from "../Home/Sidebar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/userSlice";

const MainLayout = ({children}) => {
  const [enjoyTheView, setEnjoyTheView] = useState(false);
  const user = useSelector(selectUser)

  return (
    <section
      role="main"
      className="w-full h-screen flex justify-center items-center relative bg-gray-600"
    >
      {user.uid && <Sidebar enjoyTheView={enjoyTheView} onEnjoyTheView={setEnjoyTheView} />}
      <div className={`z-10 ${enjoyTheView? 'scale-0' : 'scale-100'} origin-center transition-all duration-300 w-screen h-screen flex justify-center items-center`}>
        {children}
      </div>
      <MainBackground />
    </section>
  );
};

export default MainLayout;
