import { useSelector } from "react-redux";
import { selectIsSignUp } from "../../store/styleSlice";

const MobileEllipse = ({ isSignUp }) => {
  return (
    <div
      className={`
        transition-all duration-300 ease-in-out
    scale-100 md:scale-0 absolute 
    -left-[20%] ${isSignUp ? "top-[80%]" : "-top-[40%]"} w-[140%] h-[66%] 
    bg-Primary bg-gradient-to-t from-Primary via-Secondary via-[1%]  to-Primary bg-size-200 animate-gradient-bg`}
      style={{ borderRadius: "100%" }}
    ></div>
  );
};

const TabletDesktopEllipse = ({ isSignUp }) => {
  return (
    <div
      className={`
    scale-0 md:scale-100 absolute 
    ${isSignUp ? "left-[50%]" : "-left-[50%]"} -top-[50%] w-full h-[200%] 
    bg-Primary bg-gradient-to-t from-Primary via-Secondary via-[1%]  to-Primary bg-size-200 animate-gradient-bg
    transition-all duration-300 ease-in-out
    `}
      style={{ borderRadius: "100%" }}
    ></div>
  );
};

const BackgroundEllipse = () => {
  const isSignUp = useSelector(selectIsSignUp);
  console.log('change isSignUp', isSignUp)
  return (
    <div className="absolute w-full h-full overflow-hidden rounded-xl">
      <div className="relative w-full h-full transition-all duration-300 ease-in-out">
        <MobileEllipse isSignUp={isSignUp} />
        <TabletDesktopEllipse isSignUp={isSignUp} />
      </div>
    </div>
  );
};

export default BackgroundEllipse;
