import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uid: null,
  displayName: null,
  email: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      // console.log("Setting user:", action.payload);
      return {
        ...state,
        uid: action.payload.uid,
        displayName: action.payload.displayName,
        email: action.payload.email,
      };
    },
    clearUser: (state) => {
      console.log("Clearing user");
      return {
        ...initialState,
      };
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export const selectUser = (state) => state.user
export default userSlice.reducer;