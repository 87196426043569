// services/Auth/SignupService.js
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";

export const validateInputs = (inputs) => {
    const errors = {};
    const { email, password, confirmPassword, fullName } = inputs;

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
        errors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
        errors.email = "Email is not valid.";
    }

    if (!password) {
        errors.password = "Password is required.";
    } else if (password.length < 6) {
        errors.password = "Password must be at least 6 characters long.";
    }

    // Validate confirm password
    if (!confirmPassword) {
        errors.confirmPassword = "Confirm Password is required.";
    } else if (confirmPassword !== password) {
        errors.confirmPassword = "Passwords do not match.";
    }

    // Validate full name
    if (!fullName) {
        errors.fullName = "Full Name is required.";
    } else if (fullName.trim().length < 3) {
        errors.fullName = "Full Name must be at least 3 characters long.";
    }

    return errors;
};

export const registerUser = async ({ email, password, fullName }) => {
    try {
        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Send email verification
        await sendEmailVerification(user, {
            url: window.location.origin + '/auth/sign-in', // Redirect URL after verification
        });

        // Save user data to Firestore
        await setDoc(doc(db, "Users", user.uid), {
            uid: user.uid,
            displayName: fullName,
            email: email,
            createdAt: new Date().toISOString(),
        });

        return {
            success: true,
            message: "Registration successful! Please check your email to verify your account."
        };
    } catch (error) {
        let errorMessage;
        switch (error.code) {
            case 'auth/email-already-in-use':
                errorMessage = "This email is already registered.";
                break;
            case 'auth/invalid-email':
                errorMessage = "Invalid email address.";
                break;
            case 'auth/operation-not-allowed':
                errorMessage = "Email/password sign up is not enabled.";
                break;
            case 'auth/weak-password':
                errorMessage = "Password is too weak.";
                break;
            default:
                errorMessage = "An error occurred during registration.";
        }
        throw new Error(errorMessage);
    }
};
