import { useLocation, useParams } from "react-router-dom";
import BackgroundEllipse from "../StylingComponents/BackgroundEllipse";
import Container from "./Container";
import { useSelector } from "react-redux";
import { selectIsSignUp } from "../../store/styleSlice";

const SignInContainer = ({ children }) => {

  

  // return <section role="container" className="bg-white rounded-3xl p-6 flex justify-center items-center relative h-[30rem] w-[15rem] sm:h-[30rem] sm:w-[50rem] overflow-clip">
  return (
    <Container extraClasses="my-24 mx-6">
      <div className="w-full sm:h-full flex flex-col justify-between items-center md:flex-row ">
        {children}
      </div>
      <BackgroundEllipse />
    </Container>
  );
};

export default SignInContainer;

