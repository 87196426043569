const Divider = () => {
    return (
      <div className="flex items-center my-2 sm:my-4">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="flex-shrink mx-2 text-gray-500 text-lg">or</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
    );
  };
  
  export default Divider;