import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../store/userSlice";
import EmailInput from "../Inputs/EmailInput";
import PasswordInput from "../Inputs/PasswordInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import Divider from "../Util/Divider";
import SignWithGoogleButton from "../Buttons/SignWithGoogleButton";
import CreativityImage from "../../assets/orange-art.svg";
import { selectIsSignUp, setStyle } from "../../store/styleSlice";
import { signInUser, validateSignInInputs } from "../../services/Auth/SigninService.js" // Import sign-in service
import LoadingSpinner from "../Util/LoadingSpinner.jsx";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDoc, setDoc } from "firebase/firestore";
import { auth } from "../../firebase.js";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSignUp = useSelector(selectIsSignUp);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async (e) => {
    e?.preventDefault();
    setIsLoading(true);

    const inputValues = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
    };

    const errors = validateSignInInputs(inputValues);
    setError(errors);

    if (Object.keys(errors).length === 0) {
        try {
            const { user } = await signInUser(inputValues);
            
            dispatch(
                setUser({
                    uid: user.uid,
                    displayName: user.displayName || user.email,
                    email: user.email,
                    
                })
            );

            navigate("/home");
        } catch (error) {
            setError({ general: error.message });
        }
    }
    setIsLoading(false);
};

  const signInWithGoogle = async (e) => {
    e.preventDefault()
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User authenticated:", user);

      const userDocRef = doc(db, "Users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          createdAt: new Date().toISOString(),
        });
      }

      // Dispatch the user details to the Redux store
      dispatch(
        setUser({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
        })
      );

      navigate("/home");
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  useEffect(() => {
    dispatch(setStyle({ isSignUp: false }));
  }, [dispatch]);

  return (
    <>
      <div className={`${isSignUp ? "scale-0" : "scale-100"}  transition-all duration-300 w-full flex justify-center items-center h-48 max-h-48 sm:max-h-[300px] sm:h-1/3 md:w-1/2 md:h-full z-10`}>
        <img src={CreativityImage} className="w-auto h-full object-cover md:pr-5" />
      </div>
      <form
        className={`${isSignUp ? "scale-0 absolute" : "scale-100"} transition-all duration-300 z-50 w-full flex pt-3 sm:pt-0 sm:h-2/3 md:w-1/2 md:h-full md:ml-10  flex-col justify-center items-center`}
      >
        <h1 className="text-fluid-title font-bold mb-3 sm:mb-10">Bienvenue</h1>
        <EmailInput ref={emailRef} />
        {error.email && <p className="text-red-500 text-sm -mt-1">{error.email}</p>}
        <PasswordInput ref={passwordRef} />
        {error.password && <p className="text-red-500 text-sm -mt-1">{error.password}</p>}
        <p className="text-blue-500 hover:text-blue-400 cursor-pointer text-sm w-full text-right mb-4"
         onClick={() => navigate("/auth/forgot-password")}
        >
         
          Forgot password?
        </p>
        {error.general && <p className="text-red-500 text-sm">{error.general}</p>}
        <PrimaryButton onClick={handleSignIn} disabled={isLoading}>Sign In {isLoading && <LoadingSpinner />}</PrimaryButton>
        <div className="mb-2"></div>
        <SignWithGoogleButton onClick={signInWithGoogle}>Sign in with Google</SignWithGoogleButton>
        <Divider />
        <p className="text-center">
          Don't have an account?{" "}
          <button
            onClick={(e) => {
              e.preventDefault()
              dispatch(setStyle({ isSignUp: true }));
              setTimeout(() => navigate("/auth/sign-up"), 300);
            }}
          >
            <span className="hover:text-blue-600 font-semibold">Sign Up here!</span>
          </button>
        </p>
      </form>
    </>
  );
}

export default SignIn;
