import GoogleIcon from '../../assets/Google-colored-icon.svg'

const SignWithGoogleButton = ({onClick, children}) => {
  return (
      <button 
          onClick={onClick}
          className="flex justify-center items-center border-1 border-Secondary text-Secondary py-2 px-4 rounded-full w-full hover:bg-Primary hover:bg-opacity-[3%] active:scale-110 transition duration-200"
      >
          <img src={GoogleIcon} className='mr-1' /> {children}
      </button>
  )
}

export default SignWithGoogleButton;