import React from 'react';
import { CheckCircle, XCircle, X } from 'lucide-react';
import { useState, useEffect } from 'react';

const Toast = ({ message, type = 'success', duration = 3000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 300); // Wait for fade out animation
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div
      className={`fixed z-50 top-4 right-4 transform transition-all duration-300 ease-in-out ${
        isVisible ? 'translate-y-0 opacity-100' : '-translate-y-4 opacity-0'
      }`}
    >
      <div className="flex items-center gap-2 min-w-[280px] max-w-md bg-white rounded-lg shadow-lg border-l-4 p-4 border-pink-500">
        <div className="shrink-0">
          {type === 'success' ? (
            <CheckCircle className="w-5 h-5 text-pink-500" />
          ) : (
            <XCircle className="w-5 h-5 text-pink-500" />
          )}
        </div>
        
        <p className="text-gray-700 flex-grow">{message}</p>
        
        <button
          onClick={() => {
            setIsVisible(false);
            setTimeout(onClose, 300);
          }}
          className="shrink-0 ml-2 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default Toast;