import { FiEye, FiEyeOff } from "react-icons/fi"; // Importing eye icons from react-icons

const ToggleSwitch = ({ enabled, onToggle }) => {
  return (
    <div
      className={`${
        enabled ? "bg-Primary" : "bg-gray-400"
      } relative inline-flex items-center h-6 w-11 rounded-full cursor-pointer transition-colors duration-300 ease-in-out`}
      onClick={onToggle}
    >
      <span
        className={`${
          enabled ? "translate-x-6" : "translate-x-1"
        } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-300 ease-in-out flex justify-center items-center`}
      >
      </span>
    </div>
  );
};

export default ToggleSwitch;
