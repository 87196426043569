import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Booking from "../Home/Booking";
import SignIn from "../Auth/SignIn";
import SignUp from "../Auth/SignUp";
import GPACalculator from "../../Pages/GPACalculator";
import { AuthRoute, GuestRoute } from "./AuthRoute";
import AuthPage from "../Auth/AuthPage";
import SignInContainer from "../Containers/SignInContainer";
import BookingHistory from "../../Pages/BookingHistory";
import ForgotPassword from "../Auth/ForgotPassword";

export const routes = [
  {
    path: "/",
    component: () => (
      <AuthRoute>
        <Navigate to="/home" />
      </AuthRoute>
    ),
  },
  {
    path: "/home",
    component: () => (
      <AuthRoute>
        <Booking />
      </AuthRoute>
    ),
  },
  {
    path: "/auth",
    component: () => (
      <GuestRoute>
        <SignInContainer>
          <Outlet />
        </SignInContainer>
      </GuestRoute>
    ),
    
    children: [
      {
        path: "sign-in",
        component: () => (
            <SignIn />
        ),
      },
      {
        path: "forgot-password",
        component: () => <ForgotPassword />,
      },
      {
        path: "sign-up",
        component: () => (
            <SignUp />
        ),
      },
    ],
  },
  {
    path: "/booking",
    component: () => (
      <AuthRoute>
        <Outlet />
      </AuthRoute>
    ),
    children: [
      {
        path: "manage-booking",
        component: () => (
          <AuthRoute>
            <BookingHistory/>
          </AuthRoute>
        ),
      },
      {
        path: "book-room",
        component: () => (
          <AuthRoute>
            <Booking />
          </AuthRoute>
        ),
      },
    ],
  },
  {
    path: "/gpa",
    component: () => (
      <AuthRoute>
        <GPACalculator />
      </AuthRoute>
    ),
  },
];

const generateRoutes = (routes) => {
  return routes.map((route) => {
    const { path, component: Component, children } = route;

    return (
      <Route key={path} path={path} element={<Component />}>
        {children && generateRoutes(children)}
      </Route>
    );
  });
};

const RouterModule = () => {
  return <Routes>{generateRoutes(routes)}</Routes>;
};

export default RouterModule;
