import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Booking from "./components/Home/Booking.jsx";
import MainLayout from "./components/Layouts/MainLayout";
import SignIn from "./components/Auth/SignIn";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, clearUser } from "./store/userSlice.js";
import { auth } from "./firebase";
import RouterModule from "./components/Routing/RouterModule.jsx";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  // console.log("User in App:", user); // Debug log to verify user state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // console.log("User is authenticated:", user);
        dispatch(
          setUser({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
          })
        );
      } else {
        // console.log("User is not authenticated");
        dispatch(clearUser());
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  // console.log("User state:", user);

  const dayMap = [
    "U", // Sunday
    "M", // Monday
    "T", // Tuesday
    "W", // Wednesday
    "R", // Thursday
    "F", // Friday
    "S", // Saturday
  ];

  return (
    <BrowserRouter>
      <MainLayout>
        <RouterModule />
      </MainLayout>
    </BrowserRouter>
  );
};

export default App;

// {
//   <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<MainLayout />}>
//           <Route
//             path="/home"
//             element={
//               user.uid ?
//               // <Home data={data} />
//               <GPACalculator/>
//                : <Navigate to="/sign-in" />
//             }
//           />
//           <Route
//             path="/sign-in"
//             element={user.uid ? <Navigate to="/home" /> : <SignIn />}
//           />
//           <Route index element={<Navigate to="/home" />} />
//           <Route path="/test" element={<h1>hello</h1>} />
//           {ROUTES.map((el, index) => {
//             console.log(el.str)
//             return <Route path={el.path} element={<h1>{el.str}</h1>}/>
//           })}

//         </Route>
//       </Routes>
//     </BrowserRouter>
// }
