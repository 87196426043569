import SearchForm from "./SearchForm";
import BookingModal from "./BookingModal";
import Pagination from "../Pagination/Pagination";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getFirestore, collection, addDoc, deleteDoc, doc } from "firebase/firestore";
import moment from "moment";
import PrimaryButton from "../Buttons/PrimaryButton";
import Logout from "../Buttons/LogoutButton";
import RadioButtons from "../Inputs/RadioButtons";
import { checkIfTimePassed, getNextDate, setStartEndBookingTimes } from "../../services/Booking/DateService";
import { handleConfirmBooking } from "../../services/Booking/Booking";
import { selectRootData } from "../../store/rootDataSlice";
import Container from "../Containers/Container";
import BookingConfirmationModal from "../../services/Booking/BookingConfirmationModal";

const Booking = () => {
  const [availableRooms, setAvailableRooms] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment().add(1, "hour"));
  const [selectedDay, setSelectedDay] = useState("");
  const data = useSelector(selectRootData);
  const buildingsPerPage = 2;
  const user = useSelector((state) => state.user);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
const [bookingDetails, setBookingDetails] = useState(null);

  const handleSearch = (filteredRooms, selectedDay, startTime, endTime) => {
    setAvailableRooms(filteredRooms);
    setSelectedDay(selectedDay);
    setStartTime(startTime);
    setEndTime(endTime);
    setCurrentPage(1);
  };

  const handleSetRoomToPick = ({ building, choice }) => {
    setSelectedRoom({ building, room: choice });
    setSelectedBuilding(building);
  };

  const handleBook = () => {
    setShowModal(true);
  };

  const onConfirmBooking = async () => {
    try {
      await handleConfirmBooking(
        selectedRoom,
        selectedBuilding,
        selectedDay,
        startTime,
        endTime,
        user,
        setAvailableRooms
      );
      
      // Show confirmation modal with booking details
      setBookingDetails({
        building: selectedRoom.building,
        room: selectedRoom.room,
        startTime: startTime,
        endTime: endTime,
        displayName: user.displayName,
        email: user.email
      });
      setShowConfirmationModal(true);
      setShowModal(false);
    } catch (error) {
      console.error("Error confirming booking:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const sortedRooms = availableRooms.reduce((acc, { room, building }) => {
    const buildingIndex = acc.findIndex((item) => item.building === building);

    if (buildingIndex === -1) {
      acc.push({ building, rooms: [room] });
    } else {
      acc[buildingIndex].rooms.push(room);
    }

    return acc;
  }, []);

  const totalPages = Math.ceil(sortedRooms.length / buildingsPerPage);
  const startIndex = (currentPage - 1) * buildingsPerPage;
  const endIndex = startIndex + buildingsPerPage;
  const paginatedRooms = sortedRooms.slice(startIndex, endIndex);

  return (
    <Container extraClasses="mx-12">
      <div>
        <h1 className="text-4xl text-Primary font-bold text-center">
          Class Finder
        </h1>
        <SearchForm
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          data={data}
          onSearch={handleSearch}
        />

        <h2 className="mt-3 mb-2 text-Primary text-2xl font-bold text-center">
          Available Rooms
        </h2>
        <div>
          {paginatedRooms.map(({ building, rooms }) => (
            <div key={building} className="mb-3">
              <RadioButtons
                choices={rooms}
                building={building}
                onSelectChoice={handleSetRoomToPick}
                selectedChoice={selectedRoom}
                selectedBuilding={selectedBuilding}
                label={`Building ${building}`}
              />
            </div>
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        <PrimaryButton onClick={handleBook}>Book</PrimaryButton>

        <BookingModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onConfirm={onConfirmBooking}
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
        />
      </div>
      <BookingConfirmationModal
  isOpen={showConfirmationModal}
  onClose={() => setShowConfirmationModal(false)}
  bookingDetails={bookingDetails}
/>
    </Container>
  );
};

export default Booking;
