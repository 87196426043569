import { forwardRef, useState } from "react";
import { CiUser } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa6";


const NameInput = forwardRef(({ text = "Full Name" }, ref) => {
  return (
    <div className="flex items-center border border-gray-300 rounded-lg p-2 w-full my-1">
      <FaRegUser className="text-gray-400 mr-1 w-6 h-6" />
      <input
        ref={ref}
        type="text"
        placeholder={text}
        className="outline-none flex-grow text-gray-600 placeholder-gray-400 w-full"
      />
    </div>
  );
});

export default NameInput;
