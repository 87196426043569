import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="pagination mb-2">
      {[...Array(totalPages).keys()].map(number => (
        <button
          key={number + 1}
          onClick={() => onPageChange(number + 1)}
          disabled={currentPage === number + 1}
        >
          {number + 1}
        </button>
      ))}
    </div>
  );
};

export default Pagination;