import { useState } from "react";
import { FaChevronDown } from "react-icons/fa"; // Install if not already installed: `npm install react-icons`

const ListSelector = ({ 
  items = [], 
  onSelectItem, 
  selectedItems = [], 
  label = 'Select Buildings' 
}) => {

  const [isOpen, setIsOpen] = useState(false);  // State to toggle list visibility

  const handleItemClick = (item) => {
    const isSelected = selectedItems.some(selected => selected.value === item.value);
    if (isSelected) {
      // Remove item if it's already selected
      onSelectItem(selectedItems.filter(i => i.value !== item.value));
    } else {
      // Add item if it's not selected
      onSelectItem([...selectedItems, item]);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);  // Toggle list open/close state
  };

  return (
    <div className="relative">
      <div className="border border-gray-300 rounded-lg w-full py-3 px-2">
        <button 
          type="button" 
          onClick={toggleDropdown} 
          className="w-full flex justify-between items-center text-left py-2 px-3 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg"
        >
          {label}
          <FaChevronDown className="ml-2 text-gray-500" />
        </button>
        
        {/* Collapsible, centered scrollable list */}
        
          <div className={`absolute z-10 flex flex-col max-h-32 overflow-y-auto w-full left-1/2 transform border-b border-x border-t-0 border-gray-200 -translate-x-1/2 mt-2 p-3 bg-white  rounded-b-lg origin-top transition-all duration-300 ${isOpen? 'scale-100' : 'scale-0'}`}>
            {items.map((item) => {
              const isSelected = selectedItems.some(selected => selected.value === item.value); // Check if this item is selected
              return (
                <button
                  key={item.value}
                  type="button"
                  className={`flex items-center justify-center w-full h-12 rounded-lg mb-1 cursor-pointer transition-colors duration-300 ${
                    isSelected
                      ? 'bg-Secondary text-white'  // Apply Primary color to selected items
                      : 'bg-gray-200 text-gray-600 hover:bg-gray-300 hover:text-gray-800'
                  }`}
                  onClick={() => handleItemClick(item)}
                >
                  {item.label}
                </button>
              );
            })}
          </div>
        
      </div>
    </div>
  );
};

export default ListSelector;
