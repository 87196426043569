import React from "react";
import "./MainBackground.css";

const MainBackground = () => {
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 30; i++) {
      stars.push(<div key={i} className="star"></div>);
    }
    return stars;
  };

  const renderPyramids = () => {
    const pyramids = [];
    for (let i = 1; i <= 4; i++) {
      pyramids.push(<div key={i} className="pyramid"></div>);
    }
    return pyramids;
  };

  const renderSquares = () => {
    const squares = [];
    for (let i = 1; i <= 4; i++) {
      squares.push(<div key={i} className="square"></div>);
    }
    return squares;
  };

  const renderMainSunReflexions = () => {
    const reflexions = [];
    for (let i = 1; i <= 20; i++) {
      reflexions.push(<div key={i} className="reflexion-shape"></div>);
    }
    return reflexions;
  };

  const renderSmallSunReflexions = () => {
    const reflexions = [];
    for (let i = 1; i <= 26; i++) {
      reflexions.push(<div key={i} className="reflexion"></div>);
    }
    return reflexions;
  };

  return (
    <div className="sky-scene absolute z-0 max-h-screen">
      <div className="top overflow-hidden">
        <div className="stars">{renderStars()}</div>
        <div className="shooting-star"></div>
        <div className="clouds">
          <div className="cloud"></div>
          <div className="cloud"></div>
          <div className="cloud"></div>
        </div>
        <div className="sun">
          <div className="sun-glow-1"></div>
          <div className="sun-glow-2"></div>
          <div className="sun-glow-3"></div>
        </div>
        <div className="pyramids">{renderPyramids()}</div>
      </div>
      <div className="bottom left-0">
        <div className="boat">
          <div className="boat-bottom">
            <div className="squares">{renderSquares()}</div>
          </div>
          <div className="boat-behind"></div>
          <div className="boat-top-left"></div>
          <div className="boat-top-right"></div>
        </div>
        <div className="boat">
          <div className="boat-bottom">
            <div className="squares">{renderSquares()}</div>
          </div>
          <div className="boat-behind"></div>
          <div className="boat-top-left"></div>
          <div className="boat-top-right"></div>
        </div>
        <div className="main-sun-reflexion">{renderMainSunReflexions()}</div>
        <div className="small-sun-reflexions">{renderSmallSunReflexions()}</div>
      </div>
    </div>
  );
};

export default MainBackground;
