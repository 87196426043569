const LoadingSpinner = () => {
  return (
    <div
      className="inline-block h-5 w-5 rounded-full border-3 border-solid border-current border-r-transparent align-[-0.125em] animate-[spin_0.5s_linear_infinite]"
      role="status"
    ></div>
  );
};

export default LoadingSpinner;
