import { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const RadioButtons = ({
  choices = [],
  onSelectChoice,
  selectedChoice,
  building = "",
  selectedBuilding,
  label = "Select Day:",
  isDaysOfWeek = false,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const visibleCount = isDaysOfWeek ? 7 : 5;
  const visibleChoices = choices.slice(startIndex, startIndex + visibleCount);

  const handleChoiceChange = (choice) => {
    if (!isDaysOfWeek) {
      onSelectChoice({ building, choice });
    } else {
      onSelectChoice(choice);
    }
  };

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayMap = ["U", "M", "T", "W", "R", "F", "S"];

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNext = () => {
    if (startIndex + visibleCount < choices.length) {
      setStartIndex(startIndex + 1);
    }
  };

  return (
    <div className="relative w-full">
      <div className="border border-gray-300 rounded-lg w-full py-2.5 px-2 sm:py-3 sm:px-2 flex items-center">
        <span className="absolute top-0 -translate-y-1/2 left-3 px-1 bg-white text-sm text-[#6B7280]">
          {label}
        </span>
        {choices.length > visibleCount && (
          <button
            onClick={handlePrev}
            disabled={startIndex === 0}
            className={`${
              startIndex === 0
                ? "text-gray-400 cursor-not-allowed"
                : "text-Primary"
            } p-2`}
          >
            <AiOutlineLeft /> {/* Left Arrow Icon */}
          </button>
        )}
        <div
          className={`flex ${isDaysOfWeek ? 'justify-between' : 'justify-center'} space-x-1 sm:space-x-4 w-full overflow-hidden`}
        >
          {visibleChoices.map((choice) => (
            <label
              key={choice}
              className={`flex items-center justify-center text-xs sm:text-sm w-10 h-10 sm:w-12 sm:h-12 rounded-full cursor-pointer transition-colors duration-300 ${
                (selectedChoice === choice && isDaysOfWeek) ||
                (!isDaysOfWeek &&
                  building === selectedBuilding &&
                  selectedChoice.room === choice)
                  ? `border border-Primary bg-Primary text-white`
                  : `border border-gray-400 text-gray-400 hover:border-gray-600 hover:text-gray-600`
              }`}
            >
              <input
                type="radio"
                name="choice"
                value={choice}
                checked={
                  (selectedChoice === choice && isDaysOfWeek) ||
                  (!isDaysOfWeek &&
                    building === selectedBuilding &&
                    selectedChoice.room === choice)
                }
                onChange={() => handleChoiceChange(choice)}
                className="hidden"
              />
              <span className="text-center">
                {isDaysOfWeek ? daysOfWeek[dayMap.indexOf(choice)] : choice}
              </span>
            </label>
          ))}
        </div>
        {choices.length > visibleCount && (
          <button
            onClick={handleNext}
            disabled={startIndex + visibleCount >= choices.length}
            className={`${
              startIndex + visibleCount >= choices.length
                ? "text-gray-400 cursor-not-allowed"
                : "text-Primary"
            } p-2`}
          >
            <AiOutlineRight /> {/* Right Arrow Icon */}
          </button>
        )}
      </div>
    </div>
  );
};

export default RadioButtons;
