import React from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { clearUser } from "../../store/userSlice"; // Import clearUser action
import { useNavigate } from "react-router-dom";
import { TbLogout } from "react-icons/tb";
import PrimaryButton from "./PrimaryButton";
import { IoLogOutOutline } from "react-icons/io5";

function Logout({isOpen = true, showText=true}) {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        dispatch(clearUser()); // Clear the user from Redux
        navigate('/')
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return <button className={`text-gray-500 group hover:text-gray-600 flex items-center ${showText && isOpen? 'p-3' : 'justify-center'}`} onClick={handleLogout}>
    <IoLogOutOutline className={`text-2xl text-gray-500 group-hover:text-gray-600`} />
    {showText && isOpen && <span className="ml-3">Logout</span>}
  </button>;
}

export default Logout;
