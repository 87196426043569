import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-coral-400 to-purple-900 flex items-center justify-center p-4">
      {/* Main container with glass effect */}
      <div className="relative bg-white bg-opacity-10 backdrop-blur-lg rounded-2xl p-8 flex flex-col items-center space-y-8">
        {/* Animated book icon */}
        <div className="relative">
          <div className="w-24 h-24 relative">
            {/* Multiple spinning rings */}
            <div className="absolute inset-0 rounded-full border-4 border-white opacity-20 animate-[spin_3s_linear_infinite]"></div>
            <div className="absolute inset-0 rounded-full border-4 border-t-[#FF9F9F] border-r-transparent border-b-transparent border-l-transparent animate-[spin_2s_linear_infinite]"></div>
            <div className="absolute inset-0 rounded-full border-4 border-r-[#FFB4B4] border-t-transparent border-b-transparent border-l-transparent animate-[spin_1.5s_linear_infinite_reverse]"></div>
            
            {/* Center icon */}
            <div className="absolute inset-0 flex items-center justify-center">
              <svg className="w-12 h-12 text-white animate-bounce" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4L4 8L12 12L20 8L12 4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4 12L12 16L20 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4 16L12 20L20 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </div>

        {/* Loading text with animated dots */}
        <div className="text-white text-xl font-medium flex items-center space-x-1">
          <span>Loading</span>
          <span className="animate-[bounce_1s_infinite]">.</span>
          <span className="animate-[bounce_1s_infinite_.2s]">.</span>
          <span className="animate-[bounce_1s_infinite_.4s]">.</span>
        </div>

        {/* Floating particles */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(12)].map((_, i) => (
            <div
              key={i}
              className={`
                absolute w-2 h-2 bg-white rounded-full opacity-20
                animate-[float_${2 + (i % 3)}s_infinite_${i * 0.2}s]
              `}
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animation: `float ${2 + (i % 3)}s infinite ${i * 0.2}s ease-in-out`
              }}
            />
          ))}
        </div>

        {/* Decorative corner accents */}
        <div className="absolute top-0 left-0 w-16 h-16 border-t-2 border-l-2 border-white opacity-20 rounded-tl-2xl" />
        <div className="absolute bottom-0 right-0 w-16 h-16 border-b-2 border-r-2 border-white opacity-20 rounded-br-2xl" />
      </div>

      {/* Add custom keyframes for animations */}
      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translate(0, 0) rotate(0deg); }
          50% { transform: translate(10px, -10px) rotate(5deg); }
        }
        
        @keyframes pulse-ring {
          0% { transform: scale(0.7); opacity: 0; }
          50% { opacity: 0.2; }
          100% { transform: scale(1.2); opacity: 0; }
        }
      `}</style>
    </div>
  );
};

export default LoadingSpinner;