import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice'; // Import the user slice
import rootDataReducer from './rootDataSlice'
import styleReducer from './styleSlice'

// Create and export the Redux store
export const store = configureStore({
  reducer: {
    user: userReducer, // Register the user slice
    rootData: rootDataReducer, // to pass the data to any component that needs it
    style: styleReducer,
  },
});

export default store;


