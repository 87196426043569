// SignUp
import React, { useEffect, useRef, useState } from "react"; // Import useState
import { auth } from "../../firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, getDocs, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch from redux
import { setUser } from "../../store/userSlice"; // Import setUser action
import EmailInput from "../Inputs/EmailInput";
import PasswordInput from "../Inputs/PasswordInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import Divider from "../Util/Divider";
import SignWithGoogleButton from "../Buttons/SignWithGoogleButton";
import CreativityImage from "../../assets/orange-art.svg";
import { selectIsSignUp, setStyle } from "../../store/styleSlice";
import NameInput from "../Inputs/NameInput";
import { registerUser, validateInputs } from "../../services/Auth/SignupService";
import LoadingSpinner from "../Util/LoadingSpinner";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";


function SignUp() {
  const db = getFirestore();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize dispatch
  const isSignUp = useSelector(selectIsSignUp);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const fullNameRef = useRef(null);

  const [error, setError] = useState({}); // Initialize error state
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleSignUp = async () => {
    setIsLoading(true);
  
    const inputValues = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      confirmPassword: confirmPasswordRef.current.value,
      fullName: fullNameRef.current.value,
    };
  
    const errors = validateInputs(inputValues);
    setError(errors);
  
    if (Object.keys(errors).length === 0) {
      try {
        // Create user in Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          inputValues.email,
          inputValues.password
        );
        const user = userCredential.user;
  
        // Update the user's display name
        await updateProfile(user, { displayName: inputValues.fullName });
  
        // Save user details to Firestore
        const userDocRef = doc(db, "Users", user.uid);
        await setDoc(userDocRef, {
          uid: user.uid,
          displayName: inputValues.fullName,
          email: user.email,
          createdAt: new Date().toISOString(),
        });
  
        // Dispatch user data to Redux
        dispatch(
          setUser({
            uid: user.uid,
            displayName: inputValues.fullName,
            email: user.email,
          })
        );
  
        // Navigate to home page
        navigate("/home");
      } catch (error) {
        console.error("Error signing up:", error);
        setError({ general: error.message });
      }
    }
  
    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(setStyle({ isSignUp: true }));
  }, []);

  return (
    <>
      <form
        className={`${
          isSignUp ? "scale-100 " : "scale-0 absolute"
        } transition-all duration-300 z-50 w-full h-2/3 md:w-1/2 md:h-full md:mr-10 flex flex-col justify-center items-center`}
        onSubmit={handleSubmit}
      >
        <h1 className="text-fluid-title font-bold mb-4 sm:mb-10">Bienvenue</h1>
        <NameInput ref={fullNameRef} />
        {error.fullName && (
          <p className="text-red-500 text-sm -mt-1">{error.fullName}</p>
        )}
        <EmailInput ref={emailRef} />
        {error.email && (
          <p className="text-red-500 text-sm -mt-1">{error.email}</p>
        )}
        <PasswordInput ref={passwordRef} />
        {error.password && (
          <p className="text-red-500 text-sm -mt-1">{error.password}</p>
        )}
        <PasswordInput ref={confirmPasswordRef} text={'Confirm Password'} />
        {error.confirmPassword && (
          <p className="text-red-500 text-sm -mt-1">{error.confirmPassword}</p>
        )}
        <div className="mb-2"></div>
        <PrimaryButton disabled={isLoading} onClick={handleSignUp}>Sign Up {isLoading && <LoadingSpinner />}</PrimaryButton>
        <div className="mb-2"></div>
        <Divider />
        <p>
          Have an account{" "}
          <button
            onClick={() => {
              setTimeout(() => {
                navigate("/auth/sign-in");
              }, 300);
              dispatch(setStyle({ isSignUp: false }));
            }}
          >
            <span className="hover:text-blue-600 font-semibold">
              Sign In here!
            </span>
          </button>
        </p>
      </form>
      <div
        className={`${
          isSignUp ? "scale-100" : "scale-0"
        } transition-all duration-300 w-full flex justify-center items-center h-48 max-h-48 sm:max-h-[300px] sm:h-1/3 md:w-1/2 md:h-full z-10 `}
      >
        <img
          src={CreativityImage}
          className="w-auto h-full object-cover md:pl-5"
        />
      </div>
    </>
  );
}

export default SignUp;
